import React from 'react';

const Won = () => {
  return (
    <>
      <h2>Congratulations!</h2>
      <p>You have won the game</p>
    </>
  );
};

export default Won;
